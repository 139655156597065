import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-regresar',
  templateUrl: './btn-regresar.component.html',
  styleUrls: ['./btn-regresar.component.scss'],
})
export class BtnRegresarComponent implements OnInit {
  @Input() color: string;
  constructor() { }

  ngOnInit() {
  }

}
