import { IClienteFiltrado } from '../../../../interfaces/index';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-seleccionar-cliente-modal-page',
  templateUrl: 'seleccionar-cliente.modal.html'
})

export class SeleccionarClienteModalComponent implements OnInit {

  @Input() clientes: Array<IClienteFiltrado>;
  clienteSeleccionado: string = undefined;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {

  }

  change(event) {

    const filtrado: IClienteFiltrado = this.clientes.filter(c => c.nombre === event.detail.value)[0];
    this.clienteSeleccionado = `${filtrado.nombre} ${filtrado.apellidos}`;

  }

  seleccionar() {

    this.modalCtrl.dismiss(this.clienteSeleccionado.trim());

  }

  onCloseClick() {

    this.modalCtrl.dismiss();

  }

}
